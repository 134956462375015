import styled from 'styled-components'
import { useContentAvailableQuery, useUserQuery } from 'api'
import { View } from 'components/layout'
import { priceButtonOptions, getButtonStatus } from '../../pages/website/Pricing/PricingButtonsStatus'
import LinkWithReferrer from '../navigation/LinkWithReferrer'
import Button from './Button'

const Text = styled.p`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
`

const CtaButton = ({ text, href, block, urlParams, onClick }) => (
  <LinkWithReferrer href={href} urlParams={urlParams}>
    <Button hover block={block} onClick={onClick}>
      {text}
    </Button>
  </LinkWithReferrer>
)

export const CourseButton = ({ slug, block, urlParams, waitlistMode, onPress }) => {
  const {
    data: { user },
  } = useUserQuery()
  const {
    data: { program, raisingFundsForBusinessProgram },
  } = useContentAvailableQuery()

  if (waitlistMode) {
    return (
      <View alignItems="center">
        <Text>We are at full capacity!</Text>
        <Button onClick={onPress} backgroundColor="error" hoverBg="darkerRed" borderColor="darkerRed">
          Join the waitlist!
        </Button>
      </View>
    )
  }

  return !user ? (
    <Button
      // text="Get Access Now!"
      // href={`/payment/${slug}`}
      onClick={scrollToBottom}
      // urlParams={urlParams}
    >
      Get Access Now!
    </Button>
  ) : (
    <CtaButton
      block={block}
      {...priceButtonOptions[slug][getButtonStatus({ user, program, slug, raisingFundsForBusinessProgram })]}
      urlParams={urlParams}
    />
  )
}

const scrollToBottom = () => {
  const isMdScreen = window.matchMedia('(min-width: 768px)').matches

  window.scrollTo({
    top: document.documentElement.scrollHeight - (!isMdScreen ? 2000 : 1200),
    behavior: 'smooth',
  })
}
