import { useEffect, useState } from 'react'
import { LeadCaptureModal } from '@components/form/LeadCaptureModal'
import { SuccessModal } from './SuccessModal'

export const homeModals = {
  report: 'report',
  masterclass: 'masterclass',
  prospectus: 'prospectus',
  ideaToExit: 'ideaToExit',
  success: 'success',
}

const downloadTitles = {
  report: "'Life After the Pandemic: Opportunities in Entrepreneurship Report'",
  masterclass: "'Masterclass: 8 Must-Have Secrets for Business Success'",
  prospectus: "'Entrepreneur Program Prospectus'",
}

export const getIdeaToExitSourceSlug = (product) => {
  const mapping = {
    accel2: 'ide-12m',
    accel1: 'ide-3m',
  }
  if (product?.paymentOptionId) {
    return mapping[product.paymentOptionId] || 'ide-full'
  }
  return 'ide-full'
}

export const HomeModals = ({ visibleModal, showSuccessModal, hideAllModals }) => {
  const [downloadTitle, setDownloadTitle] = useState('')

  useEffect(() => {
    if (visibleModal && visibleModal !== homeModals.success) {
      setDownloadTitle(downloadTitles[visibleModal])
    }
  }, [visibleModal])

  if (!visibleModal) return null

  return (
    <>
      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="white-paper"
        visible={visibleModal === homeModals.report}
        onClose={hideAllModals}
        topText="Learn How You Can Build A Thriving Business"
        webImgSrc="home/pdf-cover-white-paper.png"
      />

      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="masterclass"
        visible={visibleModal === homeModals.masterclass}
        onClose={hideAllModals}
        topText="Discover The Eight Must-Have Secrets For Business Success"
        webImgSrc="home/pdf-cover-masterclass.png"
      />

      <LeadCaptureModal
        onSubmitSuccess={showSuccessModal}
        includePhone
        noImageBorderRadius
        source="program-prospectus"
        visible={visibleModal === homeModals.prospectus}
        onClose={hideAllModals}
        topText="Download the Entrepreneur Seminar Prospectus"
        webImgSrc="home/prospectus_front_cover.png"
      />

      <LeadCaptureModal
        includePhone
        noImageBorderRadius
        source={getIdeaToExitSourceSlug(visibleModal)}
        visible={!!visibleModal?.ideaToExit}
        onClose={hideAllModals}
        topText="Join the waitlist for Idea to Exit program!"
        description={visibleModal?.description}
        webImgSrc="programs/accelerator-devices.png"
        redirectOnSuccess="/entrepreneur-learning-mentoring-course/acceleratorProgram/thank-you"
      />

      <SuccessModal
        downloadTitle={downloadTitle}
        visible={visibleModal === homeModals.success}
        onClose={hideAllModals}
      />
    </>
  )
}
