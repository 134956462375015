import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Icon } from 'components/images'
import { media } from 'styled-bootstrap-grid'
import theme from 'theme/variants/theme'
import { CarouselCard } from './CarouselCard'

const SliderComponent = styled(Slider)`
  max-width: 100vw;
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(70, 174, 207, 0.5);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  ${media.md`
    width: 65px;
    height: 65px;
  `}
`

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
`

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
`

const NextArrow = ({ onClick }) => {
  return (
    <NextArrowButton onClick={onClick}>
      <Icon name="chevron-right" size={32} color={theme.colors.offWhite} />
    </NextArrowButton>
  )
}

const PrevArrow = ({ onClick }) => {
  return (
    <PrevArrowButton onClick={onClick}>
      <Icon name="chevron-left" size={32} color={theme.colors.offWhite} />
    </PrevArrowButton>
  )
}

export const CarouselTestimonials = () => {
  const sliderRef = useRef(null)

  const sliderConfig = {
    ...fixedSliderSettings,
    nextArrow: <NextArrow onClick={() => sliderRef.current?.slickNext()} />,
    prevArrow: <PrevArrow onClick={() => sliderRef.current?.slickPrev()} />,
  }

  return (
    <SliderComponent ref={sliderRef} {...sliderConfig}>
      {videoPreviews.map((video, i) => (
        <CarouselCard
          key={`${video.author}-${i}`}
          videoUrl={video.url}
          quote={video.quote}
          author={video.author}
          // role={video.role}
        />
      ))}
    </SliderComponent>
  )
}

const fixedSliderSettings = {
  dots: false,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 998,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const videoPreviews = [
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/39evrur1d0',
    quote:
      "I'm incredibly grateful for the time that Martin has dedicated to me; having someone of his calibre as a mentor has been truly transformative.",
    author: 'Blamah Sarnor',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/jn3tscc55k',
    quote:
      'Huge thanks to Martin for his invaluable guidance! He’s shown me that entrepreneurship is as much about mindset as it is about strategy. Now, I feel equipped to tackle challenges with confidence.',
    author: 'Toby Webber',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/b7zviifhov',
    quote:
      'Martin’s mentorship has been invaluable! His insights and dedication have helped us grow, and his support is truly inspiring. Grateful to keep learning from him!',
    author: 'Patrick Kinzonzi',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/4sr8dmy0yl',
    quote:
      'The Entrepreneur Seminar program has been a game-changer! The support team is amazing, and Martin’s insights are incredibly helpful. His guidance is clear, timely, and invaluable. Highly recommend to anyone ready to level up their business!',
    author: 'Gina Bozok',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/fs2s0hflwk',
    quote:
      'The Elite Entrepreneur Seminar program was a game-changer! Martin’s focus on long-term strategy and tech for social impact helped transform our operations. The support and insights were invaluable—highly recommended!',
    author: 'Mostafa Karim',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/txe6k6hmy9',
    quote:
      'Martin’s program provided the resources, tools, and mentorship I needed to make the right decisions for my startup. His guidance and inspiration have been invaluable!',
    author: 'Nicole Avram',
  },
  {
    url: 'https://entrepreneurseminar-es.wistia.com/medias/s2tbfkwfkr',
    quote:
      'Your practical advice and some of the insights you gave have really helped me to push through some obstacles towards the success of my entrepreneurial journey.',
    author: 'Jim Phene',
  },
]
