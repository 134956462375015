import React from 'react'
import styled from 'styled-components'
import { renderSvg } from 'components/images/renderSvg'
import { View } from 'components/layout'
import { WistiaPlayer } from 'components/video'
import { camelCase } from 'lodash/fp'

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  min-height: 420px;

  @media (min-width: 998px) and (max-width: 1200px), (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`

const VideoThumbnail = styled.div`
  position: relative;
  width: 54%;
  border-radius: 22px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  margin-right: -60px;

  @media (min-width: 998px) and (max-width: 1200px), (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
  }
`

const TestimonialBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 80px;
  padding-right: 32px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  min-height: 270px;
  background-color: ${(props) => props.theme.colors.white};

  @media (min-width: 998px) and (max-width: 1200px), (max-width: 600px) {
    width: 86%;
    margin-top: -18px;
    padding: 42px 32px 42px 32px;
  }
`

const Quote = styled.p`
  margin-bottom: 8px;
  font-size: 1rem;
  line-height: 1.3 rem;
  color: ${(props) => props.theme.colors.primary};
`

const Author = styled.p`
  margin: 4px 0 2px;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
`

// const Role = styled.p`
//   font-size: 0.75rem;
//   color: ${(props) => props.theme.colors.gray};
// `

const QuoteIcon = () => renderSvg('quote-left', 16)

export const CarouselCard = ({ quote, author, videoUrl, onPlay, onPause, onEnded }) => (
  <CardContainer>
    <VideoThumbnail>
      <WistiaPlayer playerId={camelCase(author)} url={videoUrl} onPlay={onPlay} onPause={onPause} onEnded={onEnded} />
    </VideoThumbnail>
    <TestimonialBox>
      <View />
      <View style={{ gap: 8 }}>
        <QuoteIcon />
        <Quote>{quote}</Quote>
      </View>
      <Author>{author}</Author>
      {/* <Role>{role}</Role> */}
    </TestimonialBox>
  </CardContainer>
)
